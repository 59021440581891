import React from 'react'
import LayoutComponent from 'Components/layout'
import error404 from '../assets/images/background/xsearch.png'
import { Typography, Box } from '@material-ui/core'

const Component = () => {
	const handleNavigate = (path) => {
		// navigate(path)
		window.location.href = path
	}
	return (
		<LayoutComponent>
			<Box p={5} textAlign='center'>
				<img src={error404} style={{ width: '40%' }} />
				<Box mt={2}>
					<Typography variant='h6'>Error: 404</Typography>
					<Typography variant='body1'>
						<b>Mohon maaf, halaman yang Anda cari tidak ditemukan.</b>
					</Typography>
					<Typography variant='body1' style={{ cursor: 'pointer' }}>
						Silahkan kembali ke <a onClick={() => handleNavigate('/')}>beranda</a>
					</Typography>
				</Box>
			</Box>
		</LayoutComponent>
	)
}

export default Component
